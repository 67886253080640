import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    products: [],
    categories: [],
    itemsInCart: [],
    collections: [],
    mainImage: ' ',
    ActiveCollectionTitle: '',
    results : [],
    targetProduct:  []
  },
  mutations: {
    addProduct (state, payload){
      state.products.push(payload)
    },
    addResults (state, payload){
      state.results.push(payload)
    },
    addActiveCollectionTitle (state, payload){
      state.ActiveCollectionTitle = payload
    },
    addCollection (state, payload){
      state.collections.push(payload)
    },
    addImage (state, payLoad) {
      state.mainImage = payLoad
    },

    addCategory (state, payload) {
      state.categories.push(payload)
    },
    addTargetProduct(state, payload) {
      state.targetProduct = payload
    },
    addItemToCart(state, payload){
      let ignore = false
      state.itemsInCart.forEach(item => {
        if(item.id === payload.id){
          // // Create seperate cart item if product has a seperate varient name
          // if(item.varientName !== payload.varientName){
            

          // } else {
          //   // If its the same varient and product, increase the quantity of the exisitng cart item
          //   item.quantity = parseInt(item.quantity) + parseInt(payload.quantity)
          //   ignore = true
          // }
          
          if(item.varientName === payload.varientName){
            item.quantity = parseInt(item.quantity) + parseInt(payload.quantity)
            ignore = true
          }
        }
      })

      if(!ignore){
        state.itemsInCart.push(payload)
      }
    },
    clearProducts(state) {
      state.products = []
    },
    clearCategories(state){
      state.categories = []
    },
    removeItem(state, payload){
      state.itemsInCart = state.itemsInCart.filter((item) => item.deleteId !== payload)
    },
    clearCart(state) {
      state.itemsInCart = []
    }, 
    clearCollections(state) {
      state.collections = []
    }, 
    clearResults(state){
      state.results = []
    }
  },
  actions: {
  },
  getters: {
    getProducts: state => {
      return state.products
    },
    getCollections: state => {
      return state.collections
    },
    getCategories: state => {
      return state.categories
    },
    getCartItems: state => {
      return state.itemsInCart
    },
    getImage: state => {
      return state.mainImage
    },
    GetActiveCollectionTitle: state => {
      return state.ActiveCollectionTitle
    },
    GetResults: state => {
      return state.results
    },
    getTargetProduct: state => {
      return state.targetProduct
    }
  }
})
