<template> 
    <div class="footer text-caption">
        <span class='copyright'> 
            COPYRIGHT © {{date.getFullYear()}} GLOBAL FURNITURE FLORIDA ALL RIGHTS RESERVED. <span> </span>BRANDING BY SWFLWEBDEV
        </span>
       
        <span>
            TERMS AND CONDITIONS
        </span>
       
        <v-icon class='icon mdi-light'> mdi-facebook </v-icon>
        <v-icon class='icon mdi-light'> mdi-instagram </v-icon>
    </div>
</template>

<script> 
    export default {
        name: 'SiteFooter',
        data() {
          return {
            date: new Date()
          }
        }
    }
</script>

<style> 
    .footer {
    background-color: black;
    margin-top: 2vh;
    max-height:15vh;
    min-height:100px;
    width: 100%;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .copyright {
    margin-right: 10px;
  }
  .icon {
    margin-left: 10px;
  }
  .computer-icon {
    margin: 0px 5px 0 10px;
  }

</style>