<template> 
    <!-- <div class='outer-wrapper'>
        <div class='image-wrapper'>
            <image-slider :mainImage='product.mainImage' :images='product.imageURLS' class='red'></image-slider>
        </div>
        <div class='content-wrapper'>
            <h1>{{product.name}}</h1>
        
        </div>
    </div> -->
    <div class='outer-wrapper mb-10'>
        <product-details-panel
        :mainImage='product.mainImage' 
        :images='product.imageURLS'
        :product='product' 
        > </product-details-panel>

        <div class='mt-md-n2 mb-6'>
            <div class='collection-title ml-5 ml-md-4 mt-10' v-if='show && itemsInCollection'><h2 class='text-h3 mb-10'> Related Items</h2></div>
             <div class='collection-title ml-5 ml-md-4 mt-lg-n16' v-if='!itemsInCollection'><h2 class='text-h3 mb-10'> Other Popular Items:</h2></div>
            <horizontal-scroll-details class='mt-5' :products="collectionProducts" v-if='show' ></horizontal-scroll-details>
        </div>
        <site-footer class='mt-5'></site-footer>
    </div>

    
</template>
<script>
import ImageSlider from '../components/ImageSlider.vue'
import ProductDetailsPanel from '../components/About/ProductDetailsPanel.vue'
import HorizontalScrollDetails from '../components/HorizontalScrollDetails.vue'
import SiteFooter from '../components/SiteFooter.vue'


export default {
    data () {
        return {
            products: this.$store.getters.getProducts,
            product: [],
            collectionProducts:[],
            show: false,
            itemsInCollection: false,
        }
    },
    created () {
        window.scrollTo(0,0)
        this.getProduct()

        if(!this.product.imageURLS[0]){
            this.product.imageURLS = []
        }
        console.log(this.product)
        
        

    },
    watch: {
        $route () {
            window.location.reload()
            window.scrollTo(0,0)
            this.getProduct()
            
        }
    },
    methods: {
        async getProduct () {
            // await this.sleep(350)
            this.products.forEach(p => {
                if(p.productSlug === this.$route.params.slug){
                    this.product = p
                    
                    // Check if the item is linked to a collection
                    
                    
                        for(let i = 0; i <= 5; i++){
                            let randomNum = Math.floor(Math.random() * this.products.length)
                            let duplicate = false
                            this.collectionProducts.forEach(product => {
                                if(product.id === this.products[randomNum].id){
                                    duplicate = true
                                }
                                
                            })
                            
                            if(!duplicate){
                              this.collectionProducts.push({
                                id: this.products[randomNum].id,
                                productName: this.products[randomNum].name,
                                price: this.products[randomNum].price, 
                                msrp: this.products[randomNum].msrp,
                                imageURL: "http:" + this.products[randomNum].mainImage,
                                slug: this.products[randomNum].productSlug

                            })
                            }
                            
                            
                            
                            
                            this.show = true
                            this.itemsInCollection = false
                        }
                    
                }
                
            })
            

        },
        sleep(ms){
                return new Promise(resolve => setTimeout(resolve, ms))
            },
    },
    components: {
        ImageSlider,
        ProductDetailsPanel,
        HorizontalScrollDetails,
        SiteFooter,

    }
}
</script>

<style scoped>
    .outer-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-x:hidden;
        height: 100%;
    }

    .content-wrapper {
        width: 50vw;
        height: 20vh;
        background-color:green;
    }
    .collection-title {
        margin-top: 1vh;
        margin-bottom: 3vh;
        width: 100%;
        
    }
</style>