import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Bedroom from '../views/Bedroom.vue'
import DiningRoom from '../views/DiningRoom.vue'
import LivingRoom from '../views/LivingRoom.vue'
import Occasional from '../views/Occasional.vue'
import ManagerSpecials from '../views/ManagerSpecials.vue'
import AboutUs from '../views/About.vue'
import ContactUs from '../views/ContactUs.vue'
import Register from '../views/Register.vue'
import ProductDetails from '../views/ProductDetails.vue'
import Search from '../views/Search.vue'
import Cart from '../views/Cart.vue'
import AcceptUser from '../views/AcceptUser.vue'
import CollectionDetails from '../views/CollectionDetails.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/bedroom/:slug',
    name: 'Bedroom',
    component: Bedroom
  },
  {
    path: '/livingroom/:slug',
    name: 'Living Room',
    component: LivingRoom
  },
  {
    path: '/diningroom/:slug',
    name: 'Dining Room',
    component: DiningRoom
  },
  {
    path: '/occasional/:slug',
    name: 'Occasional',
    component: Occasional
  },
  {
    path: '/ManagerSpecials/:slug',
    name: 'Manager Specials',
    component: ManagerSpecials
  },
  {
    path: '/aboutus',
    name: 'Our Story',
    component: AboutUs
  },
  {
    path: '/contactus',
    name: 'Contact Us',
    component: ContactUs
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/products/:slug',
    name: 'productdetails',
    component: ProductDetails
  },
  {
    path: '/collection/:slug',
    name: 'collectiondetails',
    component: CollectionDetails
  },
  {
    path: '/search/',
    name: 'Search',
    component: Search
},
{
  path: '/Cart',
  name: 'Cart',
  component: Cart
},
{
  path: '/AcceptUser/:email/:name/:address/:companyName',
  name: 'AcceptUser',
  component: AcceptUser
}

]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
