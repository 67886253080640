<template> 
     <div class='outer-wrapper mb-10'>
        <CollectionDetailsPanel>
        :collection='mainCollection',
        > </CollectionDetailsPanel>

        <!-- <div class='mt-md-n2 mb-6'>
            <div class='collection-title ml-5 ml-md-4 mt-10' v-if='show && itemsInCollection'><h2 class='text-h3 mb-10'> Related Items</h2></div>
             <div class='collection-title ml-5 ml-md-4 mt-lg-n16' v-if='!itemsInCollection'><h2 class='text-h3 mb-10'> Other Popular Items:</h2></div>
            <horizontal-scroll-details class='mt-5' :products="collectionProducts" v-if='show' ></horizontal-scroll-details>
        </div> -->
        <site-footer class='mt-5'></site-footer>
    </div>

    
</template>
<script>
import ImageSlider from '../components/ImageSlider.vue'
import HorizontalScrollDetails from '../components/HorizontalScrollDetails.vue'
import SiteFooter from '../components/SiteFooter.vue'
import CollectionDetailsPanel from '../components/About/CollectionDetailsPanel.vue'
import axios from "axios"


export default {
    data () {
        return {
            products: [],
            product: [],
            collectionProducts:[],
            show: false,
            itemsInCollection: false,
            collections: [],
            mainCollection: [],
            mainImage: '',
        }
    },
    async created () {
        window.scrollTo(0,0)
        //this.getProduct()

        //if(!this.product.imageURLS[0]){
            //this.product.imageURLS = []
       // }
        //console.log(this.product)
        
        var productIDS = ''
        this.$store.commit('clearResults')
        this.collections = this.$store.getters.getCollections
        
        let array = this.$store.getters.getCollections
        array.forEach(arr => {
            arr.forEach(collection => {
                if(collection.collectionName === this.$route.params.slug){
                    this.mainCollection = collection
                    collection.collectionProducts = collection.collectionProducts.replaceAll("'","")
                    collection.collectionProducts = collection.collectionProducts.split(',')
                    collection.collectionProducts.forEach(id => {
                        productIDS += '"' + id + '"' + ","
                    })
                    this.$store.commit('addImage', collection.collectionImage)
                    this.$store.commit('addActiveCollectionTitle', collection.collectionName) 
                    return
                }
            })
        })

        productIDS = productIDS.slice(0,-1)
        let results = await this.getProductSQL("placeholder", productIDS)
        this.buildProductFromSQLData(results.data)
        
    },
    watch: {
        $route () {
            window.location.reload()
            window.scrollTo(0,0)
            
            //this.getProduct()
            
        }
    },
    methods: {
        async getProductSQL(category, productIDS){
                const params = {
                    Category: category,
                    getCollection: true,
                    idString: productIDS,
                }

                return axios.get("https://hyx3msvo51.execute-api.us-east-1.amazonaws.com/GetProductSQL", { params })
                    .then((response) => {
                        console.log('Successfully Connected')
                        return response
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
        async getProduct () {
            // await this.sleep(350)
            this.products.forEach(p => {
                if(p.productSlug === this.$route.params.slug){
                    this.product = p
                    
                    // Check if the item is linked to a collection
                    if(p.collectionIDS.length > 0){
                        this.show = true
                        p.collectionIDS.forEach(id => {
                            this.products.forEach(p2 => {
                                if(id === p2.id){
                                    this.collectionProducts.push({
                                        id: p2.id,
                                        productName: p2.name,
                                        price: p2.price,
                                        msrp: p2.msrp,
                                        imageURL: "http:" + p2.mainImage,
                                        slug: p2.productSlug
                                    })
                                }
                            })
                        })
                        this.itemsInCollection = true
                    }
                    else {
                        for(let i = 0; i <= 5; i++){
                            let randomNum = Math.floor(Math.random() * this.products.length)
                            let duplicate = false
                            this.collectionProducts.forEach(product => {
                                if(product.id === this.products[randomNum].id){
                                    duplicate = true
                                }
                                
                            })
                            
                            if(!duplicate){
                              this.collectionProducts.push({
                                id: this.products[randomNum].id,
                                productName: this.products[randomNum].name,
                                price: this.products[randomNum].price, 
                                msrp: this.products[randomNum].msrp,
                                imageURL: "http:" + this.products[randomNum].mainImage,
                                slug: this.products[randomNum].productSlug

                            })
                            }
                            
                            
                            
                            
                            this.show = true
                            this.itemsInCollection = false
                        }
                    }
                }
                
            })
            

        },
        buildProductFromSQLData(ProductData){
                this.$store.commit('clearProducts')
                const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        })


                ProductData.forEach(sqlData => {
                        console.log(sqlData)
                        var product = {
                            id: sqlData.productID,
                            name: sqlData.productName,
                            category: sqlData.productCategory, 
                            productRoom: sqlData.productRoom, 
                            oldPrice:parseFloat(sqlData.productPrice),
                            price: formatter.format(parseFloat(sqlData.productPrice)),
                            msrp: parseFloat(sqlData.productMSRP),
                            description: sqlData.productDescription,
                            collectionIDS: sqlData.collectionIDS, 
                            mainImage: sqlData.productImage,
                            imageURLS: sqlData.productImages.split(','), 
                            varients: (sqlData.varientLink === 'undefined') ? [] : sqlData.varientLink.split(','),
                            weight: parseFloat(sqlData.productWeight),
                            length: parseFloat(sqlData.productLength), 
                            width: parseFloat(sqlData.productWidth), 
                            height: parseFloat(sqlData.productHeight),
                            cbm: parseFloat(sqlData.productCBM),
                            productSlug: sqlData.productSlug,
                            stock: parseInt(sqlData.productStock), 
                            restockDate: (sqlData.productRestockDate === 'undefined') ? undefined : sqlData.productRestockDate,
                        }

                        this.$store.commit('addResults', product)
                        this.$store.commit('addProduct', product)
                })
        },
        sleep(ms){
                return new Promise(resolve => setTimeout(resolve, ms))
            },
    },
    components: {
        ImageSlider,
        CollectionDetailsPanel,
        HorizontalScrollDetails,
        SiteFooter,

    }
}
</script>

<style scoped>
    .outer-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-x:hidden;
        height: 100%;
    }

    .content-wrapper {
        width: 50vw;
        height: 20vh;
        background-color:green;
    }
    .collection-title {
        margin-top: 1vh;
        margin-bottom: 3vh;
        width: 100%;
        
    }
</style>