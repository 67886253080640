<template>
    <div class='d-flex justify-center align-center outer-wrapper'>
          <div class='main-wrapper'>
            <div class='category-selector'> 
               
            <a href='#scroll' class='category-link'>
                <category-grid class='d-sm-none mb-10' @change-category='changeCategory' :room='room' > </category-grid>
            </a>

            </div>
            <product-list :products="activeProducts" class='mb-8' id='scroll'></product-list>
            <SiteFooter> </SiteFooter>
        </div>     
    </div>    
</template>

<script> 
import store from '../store'
import axios from "axios"
import CategoryHorizontalScroll from '../components/CategoryHorizontalScroll.vue'
import CategoryGrid from '../components/CategoryGrid.vue'
import ProductList from '../components/ProductList.vue'
import SiteFooter from '../components/SiteFooter.vue'
export default {
    components: {
        CategoryHorizontalScroll,
        CategoryGrid,
        ProductList,
        SiteFooter
    },
        data () {
            return {
                products: [],
                sortedProducts: [],
                activeProducts: ['test'],
                room: 'Manager Specials',
                activeCategory: 'Vanity',
            }
        },

         async created () {
            window.scrollTo(0,0)
            this.$store.commit('clearProducts')
            let productSQL = ''
            productSQL = await this.getProductSQL(this.$route.params.slug)

            productSQL = productSQL.data
            this.buildProductFromSQLData(productSQL)

            this.activeProducts = this.$store.getters.getProducts   
        },
        async mounted(){
            //await this.sleep(350)

        },

        methods: { 
            changeCategory(e){
                this.activeProducts = this.sortedProducts.filter( p => p.category.trim() === e.trim())
            },
            async SortByRoom(room) {
                var array = this.products.filter( (p) => p.room === room)
                //array = this.ShuffleProducts(array)
                 array = array.sort()
                this.sortedProducts = array
            },

            sleep(ms){
                return new Promise(resolve => setTimeout(resolve, ms))
            },
            ShuffleProducts(products){
               for(var i = products.length - 1; i > 0; i--) {
                   var j = Math.floor(Math.random() * (i + 1))
                   var temp = products[i]
                   products[i] = products[j]
                   products[j] = temp
               }
               return products
            },
            async getProductSQL(category){
                const params = {
                    Category: category
                }

                return axios.get("https://hyx3msvo51.execute-api.us-east-1.amazonaws.com/GetProductSQL", { params })
                    .then((response) => {
                        console.log('Successfully Connected')
                        return response
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            buildProductFromSQLData(ProductData){
                ProductData.forEach(sqlData => {
                        var product = {
                            id: sqlData.productID,
                            name: sqlData.productName,
                            category: sqlData.productCategory, 
                            productRoom: sqlData.productRoom, 
                            price: parseFloat(sqlData.productPrice),
                            msrp: parseFloat(sqlData.productMSRP),
                            description: sqlData.productDescription,
                            collectionIDS: sqlData.collectionIDS, 
                            mainImage: sqlData.productImage,
                            imageURLS: sqlData.productImages.split(','), 
                            varients: (sqlData.varientLink === 'undefined') ? [] : sqlData.varientLink.split(','),
                            weight: parseFloat(sqlData.productWeight),
                            length: parseFloat(sqlData.productLength), 
                            width: parseFloat(sqlData.productWidth), 
                            height: parseFloat(sqlData.productHeight),
                            cbm: parseFloat(sqlData.productCBM),
                            productSlug: sqlData.productSlug,
                            stock: parseInt(sqlData.productStock), 
                            restockDate: (sqlData.productRestockDate === 'undefined') ? undefined : sqlData.productRestockDate,
                        }

                        this.$store.commit('addProduct', product)
                })
            }, 
        }
        
    
}

</script>

<style scoped>
    .main-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .category-selector {
            width: 90vw;
        }
    .category-link {
        text-decoration: none;
    }
</style>