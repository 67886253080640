<template> 
    <v-app-bar
        app
        elevation="4"
        prominent
        elevate-on-scroll
        shrink-on-scroll
        class='white'  
    >
        <div class='firstDiv d-flex justify-space-around' id="app"> 
            <div class='logodiv d-flex align-center'>
                <!-- Display a smaller logo on screens smaller than 768px wide -->  
                <router-link to='/' class='d-flex align-center'> 
                    <v-img 
                        src='https://images-global-icon.s3.us-east-2.amazonaws.com/globalflogo-webdesktop2.22ef59cf.png' 
                        max-height='150%' 
                        contain
                        href='/'> 
                    </v-img>
                </router-link>
            </div>
        
            <div class='
                productMenu 
                d-flex 
                align-center 
                justify-end
                justify-md-space-between
            '>
        
                <div class='ui-div'> 
                    <ul class='
                        ul_flex 
                        text-xl-h6
                        text-lg-subtitle-1
                        text-md-subtitle-2
                        text-sm-body-1
                        align-center 
                        justify-space-between
                        d-none
                        d-md-flex
                        mr-xl-0
                        
                    '> 
                        

                        <li><v-menu offset-y v-model="menu" class="elevation-0">  
                            <template v-slot:activator="{ on, attrs }"> 
                            <v-btn v-bind="attrs"  v-on="on" text 
                            class=" custom-btn
                                    text-xl-h6
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-body-1
                                    text-none
                                    elavation-0"
                                    
                                    
                                    > Bedroom </v-btn>
                            </template>
                            
                            <v-list class="elevation-0 ul-menu-2" >
                                <ul class="ul-menu">
                                    <li><v-list-item class="elevation-0 ul-menu-li " > <v-list-item-title> <router-link to='/bedroom/Bedroom Collections'> Bedroom Collections </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li "> <v-list-item-title> <router-link to='/bedroom/King Bed'> King Beds</router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/bedroom/Queen Bed%20'> Queen Beds</router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/bedroom/Full Bed %2f Twin Bed'> Full Beds / Twin Beds</router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/bedroom/Dresser'> Dressers</router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/bedroom/%20'> Chests </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/bedroom/Vanity%20'> Vanities </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/bedroom/Mirror%20'> Mirrors </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/bedroom/Nightstand%20'> Nightstands </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li hide"> <v-list-item-title> <router-link to='/bedroom/Bench%20'> Benches </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li hide"> <v-list-item-title> <router-link to='/bedroom/Desk'> Desks </router-link> </v-list-item-title></v-list-item></li>
                                </ul> 
                            </v-list>
                        </v-menu></li>

                        <li> <v-menu offset-y v-model="menu" class="elevation-0">  
                            <template v-slot:activator="{ on, attrs }"> 
                            <v-btn v-bind="attrs"  v-on="on" text 
                            class=" custom-btn
                                    text-xl-h6
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-body-1
                                    text-none
                                    elavation-0"
                                    
                                    
                                    > Dining Room </v-btn>
                            </template>
                            
                            <v-list class="elevation-0 ul-menu-2" >
                                <ul class="ul-menu">
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/diningroom/Dining Room Collections'> Dining Room Collections </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/diningroom/Dining Room  Sets'> Dining Room Sets </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li " > <v-list-item-title> <router-link to='/diningroom/Dining Chair'> Dining Chairs </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li "> <v-list-item-title> <router-link to='/diningroom/Dining Table%20'> Dining Tables</router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/diningroom/Stools%20'> Stools </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/diningroom/Bar Table%20'> Bar Tables </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li hide"> <v-list-item-title> <router-link to='/diningroom/Buffet%20'> Buffet </router-link> </v-list-item-title></v-list-item></li>
                                </ul> 
                            </v-list>
                        </v-menu></li>
                        
                         <li> <v-menu offset-y v-model="menu" class="elevation-0">  
                            <template v-slot:activator="{ on, attrs }"> 
                            <v-btn v-bind="attrs"  v-on="on" text 
                            class=" custom-btn
                                    text-xl-h6
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-body-1
                                    text-none
                                    elavation-0"
                                    
                                    
                                    > Living Room </v-btn>
                            </template>
                            
                            <v-list class="elevation-0 ul-menu-2" >
                                <ul class="ul-menu">
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/livingroom/Living Room Collections'> Living Room Collections </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/livingroom/Living Room  Sets%20'> Living Room Sets </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li " > <v-list-item-title> <router-link to='/livingroom/Loungers'> Loungers </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li "> <v-list-item-title> <router-link to='/livingroom/Pullout Sofa Bed'> Pullout Sofa Beds</router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/livingroom/Sofa'> Sofas </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/livingroom/Loveseat'> Loveseats</router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/livingroom/Chair'> Chairs </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/livingroom/Reclining Chair%20'> Reclining Chairs </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/livingroom/Reclining Loveseat%20'> Reclining Loveseats </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li hide"> <v-list-item-title> <router-link to='/livingroom/Reclining Sofa'> Reclining Sofas </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li hide"> <v-list-item-title> <router-link to='/livingroom/Sectional%20'> Sectionals </router-link> </v-list-item-title></v-list-item></li>

                                </ul> 
                            </v-list>
                        </v-menu></li>   
                        
                        <li> <v-menu offset-y v-model="menu" class="elevation-0">  
                            <template v-slot:activator="{ on, attrs }"> 
                            <v-btn v-bind="attrs"  v-on="on" text 
                            class=" custom-btn
                                    text-xl-h6
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-body-1
                                    text-none
                                    elavation-0"
                                    
                                    
                                    > Occasional </v-btn>
                            </template>
                            
                            <v-list class="elevation-0 ul-menu-2" >
                                <ul class="ul-menu">
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/occasional/Coffee %20'> Coffee Tables </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li " > <v-list-item-title> <router-link to='/occasional/Rugs'> Rugs </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li "> <v-list-item-title> <router-link to='/occasional/End Table'> End Tables</router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li hide"> <v-list-item-title> <router-link to='/occasional/Sofa Table%20'> Sofa Tables </router-link> </v-list-item-title></v-list-item></li>

                                </ul> 
                            </v-list>
                        </v-menu></li>    
                        
                        <li> <v-menu offset-y v-model="menu" class="elevation-0">  
                            <template v-slot:activator="{ on, attrs }"> 
                            <v-btn v-bind="attrs"  v-on="on" text 
                            class=" custom-btn
                                    text-xl-h6
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-body-1
                                    text-none
                                    elavation-0"
                                    
                                    
                                    > Manager Special </v-btn>
                            </template>
                            
                            <v-list class="elevation-0 ul-menu-2" >
                                <ul class="ul-menu">
                                    <li><v-list-item class="elevation-0 ul-menu-li"> <v-list-item-title> <router-link to='/ManagerSpecials/Manager Crazy Deals%20'> Manager Crazy Deals </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li " > <v-list-item-title> <router-link to='/ManagerSpecials/Arrivals'> New Arrivals </router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li "> <v-list-item-title> <router-link to='/ManagerSpecials/Free'> Free</router-link> </v-list-item-title></v-list-item></li>
                                    <li><v-list-item class="elevation-0 ul-menu-li hide"> <v-list-item-title> <router-link to='/ManagerSpecials/Coupons'> Coupons </router-link> </v-list-item-title></v-list-item></li>

                                </ul> 
                            </v-list>
                        </v-menu></li> 
            
                        <li> <router-link to='/aboutus/'> Our Story </router-link></li>
                        <li> <router-link to='/contactus/'> Contact Us </router-link> </li>
                    </ul>
                </div>
            
                <div class='icons d-flex justify-space-around pr-13 pr-sm-5 pr-md-0 mr-4 mr-md-3'> 
                    <a href=/search/ class='magnify-link'><v-icon class='ml-md-0'> mdi-magnify </v-icon></a>
                    <!-- Implement Menu Slider possibly --> 
                    <div class="text-center d-flex align-center">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color='white'
                                elevation='0'
                                small
                                dark
                                fab
                                class=''
                                transparent
                                v-bind="attrs"
                                v-on="on"
                                >
                                    <v-icon class='mdi-dark'> mdi-account </v-icon>
                                </v-btn>
                            </template>
                            <v-list v-if="!$auth.isAuthenticated">
                                <v-list-item>
                                    <v-btn
                                        elevation="4"
                                        outlined
                                        text
                                        @click='login'
                                    >
                                        Sign In
                                    </v-btn>
                                </v-list-item>
                                <v-list-item>
                                    <v-btn
                                        elevation="4"
                                        outlined
                                        text
                                        href='/register'
                                    >
                                        Become A Dealer
                                </v-btn>
                             </v-list-item>
                            </v-list>
                            <v-list v-if='$auth.isAuthenticated' >
                                <v-list-item @click='logout'>
                                    <v-list-item-title>Log Out</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <!-- <a href=/cart class='magnify-link'><v-icon class='mr-5 ml-md-0'> mdi-cart-outline </v-icon></a> -->
                    <router-link to='/cart/' class='magnify-link' ><v-icon class=' ml-md-0 magnify-link'> mdi-cart-outline </v-icon></router-link>
                    
                    <div class="text-center d-flex align-center d-md-none">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }" class='mobile-menu'>
                                <v-btn
                                color='white'
                                elevation='0'
                                small
                                dark
                                fab
                                class=''
                                transparent
                                v-bind="attrs"
                                v-on="on"
                                >
                                    <v-icon class=' ml-md-0 d-md-none mdi-dark' @click='overlay = !overlay'> mdi-menu </v-icon>
                                </v-btn>
                            </template>
                            <v-list class='mobile-link-column'>
                                <v-list-item class=' mobile-menu-divider'  >
                                    <v-btn
                                        elevation="4"
                                        outlined
                                        text
                                        width='100vw'
                                        height='10px'
                                        class='d-none'
                                    >
                                    </v-btn>
                                </v-list-item>
                                    
                                <v-list-item>
                                    <router-link to='/bedroom/all' class='mobile-link'> Bedroom </router-link>
                                </v-list-item>

                                <v-list-item>
                                     <router-link to='/diningroom/all' class='mobile-link'> Dining Room </router-link>
                                </v-list-item>

                                <v-list-item>
                                    <router-link to='/livingroom/all' class='mobile-link'> Living Room </router-link>
                                </v-list-item>

                                <v-list-item>
                                    <router-link to='/occasional/all' class='mobile-link'> Occasional </router-link>
                                </v-list-item>

                                <v-list-item>
                                    <router-link to='/ManagerSpecials/all' class='mobile-link'> Manager Special </router-link>
                                </v-list-item>

                                <v-list-item>
                                    <router-link to='/aboutus/' class='mobile-link'> Our Story </router-link>
                                </v-list-item>

                                <v-list-item>
                                    <router-link to='/contactus/' class='mobile-link'> Contact Us </router-link>
                                </v-list-item>





                             <!-- <li> <router-link to='/bedroom/all'> Bedroom </router-link> </li>
                        <li> <router-link to='/diningroom/all'> Dining Room </router-link> </li>
                        <li> <router-link to='/livingroom/all'> Living Room </router-link> </li>
                        <li> <router-link to='/occasional/all'> Occasional </router-link> </li>
                        <li> <router-link to='/ManagerSpecials/all'> Manager Special </router-link> </li>
                        <li> <router-link to='/aboutus/'> Our Story </router-link></li>
                        <li> <router-link to='/contactus/'> Contact Us </router-link> </li> -->

                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </div>
        </div>
     </v-app-bar>

</template>
<script> 

export default {
    name: 'Menu',
    data () {
        return {
            snackbar: true,
            items: [
                {title: 'Sign In'},
                {title: 'Logout'}
            ],
            overlay: false,
        }
    },
    methods: {
        login () {
            this.$auth.loginWithRedirect();
        },
        logout () {
            this.$auth.logout({
                returnTo: window.location.origin
            });
        }
    }
}

</script>

<style scoped> 

 .custom-btn::before{
    background-color: transparent;
 }
    .custom-btn:hover {
        text-decoration: underline;
    }
    .v-menu__content {
        box_shawdow: none;
    }

    .firstDiv {
    width: 100%;
    height: 100%;
    }

    .logodiv a {
       width: 100%;
       height: 100%;
    }

    .logodiv {
        height: 100%;
        width: 18%;
        min-width: 130px;
    }

    .productMenu {
        width: 75%;
        height:  100%;
    }
    .v-input__control {
        max-width: 587px;
    }
    .ul_flex {
        display: flex;
        list-style-type: none;
        height: 100%;
        width: 80%;
      
    }
   
    .ul_flex li:hover {
        text-decoration: underline;
    }

    .ui-div {
      
        height: 100%;
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;

    }
    li a{
        color: black;
        text-decoration: none;
    }

    

    .ul-menu {
        max-height: 150px;
        list-style:none;
         display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: wrap;
         flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
    }
        

    .ul-menu-li {
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: flex-start;
    align-self: flex-start;
    border-right: 1px solid;
    min-width: 220px;
    width:100%;
    }

    .ul-menu-li:hover {
        text-decoration: underline;
    }

    .hide {
        border-right: 0px solid;
    }

    v-list-item-title {
    }

    .signin {
        width: 20%;
        height: 100%;
        display: flex;
        text-decoration: none;
        margin-top:5;
    }

    .icons {
        width: 20%;
        height: 100%;
        margin-left: -160px;
    }
    .magnify-link {
        text-decoration: none;
        display:flex;
    }
    .mobile-menu-divider {
        width: 500px;
    }
    .mobile-link-column {
        margin-top: -8vh;
    }
    .mobile-link {
        text-decoration: none;
        color: black;
    }
</style>

