import { render, staticRenderFns } from "./VueForm.vue?vue&type=template&id=66fb0050&scoped=true"
import script from "./VueForm.vue?vue&type=script&lang=js"
export * from "./VueForm.vue?vue&type=script&lang=js"
import style0 from "./VueForm.vue?vue&type=style&index=0&id=66fb0050&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66fb0050",
  null
  
)

export default component.exports